import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const bmg = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>BMG</h1>
          <span className="highlight"></span>
        </span>
        <span>Signage Print Advertising</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/bmg_feature.jpg'} alt="BMG" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/Gatsby.svg'} width="139" height="87" alt="Gatsby" />
            </div>
            <a className="button visitwebsite" href="https://bmgaus.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a> 

          </div>

        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>At BMG, we’re not just about managing projects; we’re about crafting experiences. Our focus is on signage, print & manufacturing, but what truly sets us apart is our comprehensive approach.</p>
        <p>Tobstar delivered a Gatsby React-Based Framework project that features full-screen functionality, seamless animations, and smooth page transitions.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/bmg-desktop-large.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/bmg-mobile-large.jpg'} alt="Mobile View" />
      </div>
      </div>

    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/qvm-winter-night-market" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">QVM Night Market</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/south-melbourne-market" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">South Melbourne Market</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default bmg


export const Head = () => (
  <>
    <title>BMG - Gatbsy Project - Tobstar</title>
<meta name="description" content="We delivered a Gatsby React-Based Framework project that features full-screen functionality, seamless animations, and smooth page transitions." />
  </>
)